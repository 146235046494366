<template>
  <div class="wrapper bg_base base_container">
        <div class="container p-contact">
          <div class="content-center">
            <div class="row base_title">
              <div class="col-md-8 text-center ml-auto mr-auto">
                <h2 class="title">
                  AUDITION
                </h2>
                <h3>
                  オーディション
                </h3>
              </div>
            </div>
          </div>
          <div class="content_frame">
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSfzRk-_gmfqYLK84X5nanZjKg8gl8zqb_pkm21yiVUNAhx8Rw/viewform" target=”_blank”><img src="common/img/top/audition_banner.png" alt="audition"></a>
            <div>
              <h4 class="text-left">
                <br>
                ▼開催中のオーディションについて
              </h4>
              <ul class="contact-attention">
                <li>現在、「トリステ4期生キャラ公開VTuberオーディション」を開催中です。<p>※なお、定期的な「恒常オーディション」は実施しておりませんので、ご注意ください。</p></li>
                <li><strong>【トリステ4期生キャラ公開VTuberオーディションについて】</strong></li>
                <li>「ホワイトフェニックス」として活動いただく方を対象とした公開オーディションです。</li>
                <li>今回は「何色よりも濃い白」の才能を持つ方を求めています。</li>
                <li>活動実績がなくとも、その白の中に「光る個性」が宿っている方をトリステは歓迎いたします。</li>
                <li><br></li>
                <li>また、公開キャラクター以外の採用も検討しております。</li>
                <li>キャラクターに合わないと感じられる方でも、トリステでの活動を希望される方はぜひご応募ください。</li>
              </ul>
            </div>
            <div>
              <h4 class="text-left">
                <br>
                ▼選考の流れ
              </h4>
              <ul class="contact-attention">
                <li>選考の大まかな流れになります（変更の可能性がございます）</li>
                <li>1次選考：オンライン応募による書類＆動画選考</li>
                <li>2次選考：Discordを通じたオンライン面接</li>
                <li>3次選考：実技試験（ない場合もございます）</li>
                <li>4次選考：最終面接（オンライン予定）</li>
                <li>　　　　　↓</li>
                <li>VTuberとしてデビュー！</li>
                <li>トリリオンステージの一員として活動して頂きます！</li>
              </ul>
            </div>
            <div>
              <h4 class="text-left">
                ▼応募要項
              </h4>
              <ul class="contact-attention">
                <li>応募に関しての必須要件となります</li>
                <li>・2年間以上継続的に、週4回以上配信が可能な方</li>
                <li>・合格後2ヶ月以内に活動開始が可能な方（応相談）</li>
                <li>・活動に対し強い向上心と積極性を持つ方</li>
                <li>・チームワークとルールを守れる方</li>
                <li>・適切な配信環境を構築可能な方（機械が苦手など、技術面に関しては事務所にてサポート致します）</li>
                <li>・歌を歌うことに抵抗がない方</li>
                <li>・健康やメンタル面に不安の無い方</li>
              </ul>
            </div>
            <div>
              <h4 class="text-left">
                ▼歓迎するスキル
              </h4>
              <ul class="contact-attention">
                <li>応募に関しての歓迎要件となります（満たしていなくても応募は可能です）</li>
                <li>・VTuberとして全く新しいなにかに挑戦する予定のある方</li>
                <li>・歌に自信があり、提供されたオリジナル曲を自分の曲として歌い上げる自信がある方</li>
                <li>・short動画制作に自信がある方</li>
                <li>・配信活動、創作活動などの実績がある方</li>
                <li>・とても尖ったパーソナリティや趣味をお持ちで、それを披露することに抵抗のない方</li>
                <li>・音楽活動に強い興味があり、音楽活動に対して向上心が強い方</li>
                <li>・自発的に企画内容を考案する能力に長けてる方</li>
                <li>・希少なスキルや特技をお持ちの方（楽器演奏、特定のゲームのランクが高いなど）</li>
                <li>※VTuberとして活動中の方も歓迎しております</li>
              </ul>
            </div>
            <div>
              <h4 class="text-left">
                ▼注意事項
              </h4>
              <ul class="contact-attention">
                <li>必ずお読みください</li>
                <li>・応募前に応募要項を必ずご確認ください</li>
                <li>・第一次選考は通過者の方にのみご連絡します（原則2週間以内にご返信致します）</li>
                <li>・オーディションに参加され得た情報は機密情報となります。（発信されないようにご注意ください）</li>
                <li>・公平性の観点を鑑みて、審査内容の詳細についてお答えすることはできません</li>
                <li>・ご入力いただいた情報は、弊社<a href="https://trillionstage.jp/#/about/privacy">プライバシーポリシー</a>に従って適切に処理致します</li>
              </ul>
            </div>
            <div>
              <h4 class="text-left">
                ▼応募
              </h4>
              <ul>
                <li>下記のリンクから応募ページにアクセスし、応募フォームを記入してください。</li>
                <li>各自のパーソナリティと才能を思う存分に発揮していただき、一緒に成長してくださることを楽しみにしております。</li>
              </ul>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSfzRk-_gmfqYLK84X5nanZjKg8gl8zqb_pkm21yiVUNAhx8Rw/viewform" target=”_blank” style="line-height: 7;"><img src="common/img/top/oubo_button.png" alt="audition"></a>
              <!-- add some new lines to avoid the button affacted by page footer under narrow screen devices -->
              <br><br><br><br>
            </div>
          </div>
        </div>
  </div>
</template>
<script>
  import { Card, Button, Comment, Checkbox } from '@/components';

  export default {
    name: 'BlogPost',
    bodyClass: 'blog-post',
    components: {
      Card,
      Comment,
      [Button.name]: Button,
      [Checkbox.name]: Checkbox
    },
    data() {
      return {
        form: {
          comment: ''
        },
      }
    }
  }
</script>
<style>
</style>
